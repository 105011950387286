import React, { useContext, useCallback, useState } from "react";
import { Link } from "gatsby";
import debounce from "lodash.debounce";

// Context
import { StoreContext } from "../context/store-context";

// Components
import { NumericInput } from "./numeric-input";

// Utils
import { formatPrice } from "../utils/format-price";
import { ProductPrice } from "./product-price";

export const LineItem = ({ item, allArtistNames }) => {
  const { removeLineItem, checkout, updateLineItem, loading } =
    useContext(StoreContext);

  const [quantity, setQuantity] = useState(item.quantity);

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback((value) => uli(value), []);

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  console.log(item);

  return (
    <div className="row item">
      <div className="item-image">
        <img src={item.variant.image.src} alt={item.title} />
      </div>

      <div className="item-details">
        <h1 className="item-title uppercase">
          <Link to={`/products/${item.variant.product.handle}/`}>
            {item.title}
          </Link>
        </h1>
        <p className="item-price">
          <ProductPrice
            price={Number(item.variant.priceV2.amount) * quantity}
            salePrice={
              item.variant.compareAtPriceV2 !== null
                ? item.variant.compareAtPriceV2.amount
                : null
            }
          />
        </p>

        <div className="item-options">
          {item.variant.selectedOptions[0].value !== "Default Title" && (
            <div className="option">
              <p>{item.variant.selectedOptions[0].name}</p>
              <p className="">{item.variant.selectedOptions[0].value}</p>
            </div>
          )}
        </div>

        <div className="item-quantity">
          <div className="quantity-title">
            <p>Quantity</p>
          </div>

          <NumericInput
            disabled={loading}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={(e) => handleQuantityChange(e.currentTarget.value)}
          />
        </div>

        <div className="item-remove">
          <button onClick={handleRemove} className="uppercase">
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};
