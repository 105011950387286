import React from "react";

export const NumericInput = ({
  onIncrement,
  onDecrement,
  disabled,
  ...props
}) => {
  return (
    <div className="quantity-buttons-container">
      <button disabled={disabled} aria-label="Decrement" onClick={onDecrement}>
        <span>–</span>
      </button>

      <span className="value">{props.value}</span>

      <button disabled={disabled} aria-label="Increment" onClick={onIncrement}>
        <span>+</span>
      </button>
    </div>
  );
};
