import React, { useContext, useEffect } from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Seo
import { PageSeo } from "../components/seo/page-seo";

// Context
import { StoreContext } from "../components/context/store-context";
import { PageSetup } from "../components/context/page-setup";

// Components
import { LineItem } from "../components/product/line-item";

// Utils
import { ProductPrice } from "../components/product/product-price";
import { PrismicRichText } from "@prismicio/react";

const Page = styled.div`
  margin: 150px 0 75px 0;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin: 50px 0 30px 0;
    padding: 0;
  }

  min-height: calc(${(props) => props.height}px - 50px - 42px - 75px);

  & .page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
  }

  & .empty-cart-container {
    flex: 100%;
    width: 100%;

    margin: 100px 0 0 0;

    & p {
      margin: 0;
      text-align: center;

      @media (max-width: 768px) {
        &.message {
          font-size: 14px;
          line-height: 20px;
        }
      }

      & a {
        display: block;
        max-width: 220px;
        margin: 30px auto 0 auto;

        font-size: 13px;
        line-height: 39px;
        letter-spacing: 0.05em;
        text-align: center;
        font-weight: 500;
        background-color: #000;
        color: #fff;

        height: 40px;
        transition: 250ms color ease, 250ms background-color ease;

        @media (max-width: 768px) {
          max-width: 180px;
          margin: 20px auto 0 auto;

          font-size: 11px;
          letter-spacing: 0.1em;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }

  & .buttons-container {
    margin: 34px 0 0 0;

    width: 100%;

    & a {
      display: block;

      height: 40px;
      width: 100%;

      font-size: 15px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.02em;

      background-color: #000;
      color: #fff;

      @media (max-width: 768px) {
        font-size: 11px;
        letter-spacing: 0.1em;
      }
    }
  }
`;

const Table = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 50px;

  & .outer-column {
    &.left {
      grid-column: 1 / 8;
      border-top: 1px solid #efefef;
    }
    &.right {
      grid-column: 9 / 13;
    }
  }

  & .item {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 50px;

    padding: 15px 0;
    border-bottom: 1px solid #efefef;

    & .item-image {
      grid-column: 1 / 3;
    }

    & .item-details {
      grid-column: 3 / 7;

      & h1,
      & p,
      & button {
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.02em;
        margin: 0;
      }
    }

    & .item-options,
    & .item-quantity,
    & .item-remove {
      margin: 20px 0;
    }

    & .item-quantity {
      & .quantity-buttons-container {
        display: grid;
        grid-template-columns: 10px 30px 10px;

        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.02em;

        & .value {
          text-align: center;
        }
      }
    }
  }

  & .totals {
    width: 100%;
    max-width: 400px;

    border-top: 1px solid #efefef;

    & .title {
      margin: 33px 0 18px 0;

      & h1 {
        font-size: 18px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0.03em;
      }
    }

    & .container {
      & .inner-grid-container {
        display: grid;
        grid-template-columns: 100px auto;
        grid-column-gap: 15px;

        & .column {
          &.left {
            & h3 {
              font-size: 12px;
              line-height: 20px;
            }
          }

          &.right {
            & p {
              font-size: 15px;
              line-height: 20px;
              letter-spacing: 0.02em;

              margin: 0;
            }
          }
        }
      }
    }

    & .promo-banner {
      border-top: 1px solid #efefef;
      margin: 33px 0 18px 0;

      & p {
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.02em;
        margin: 18px 0 0 0;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .outer-column {
      &.left {
        grid-column: unset;
      }
      &.right {
        grid-column: unset;

        & .totals {
          border-top: 0;
          padding: 0 10px;
          margin: 30px 0 0 0;

          & .title {
            margin: 0 0 1em 0;

            & h1 {
              font-size: 14px;
              line-height: 17px;
            }
          }

          & .container {
            & .inner-grid-container {
              grid-template-columns: 80px auto;

              & .column {
                &.left {
                  & h3 {
                    font-size: 13px;
                    line-height: 19px;
                  }
                }

                &.right {
                  & p {
                    font-size: 13px;
                    line-height: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }

    & .item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;

      padding: 10px;

      & .item-image {
        grid-column: 1;
      }

      & .item-details {
        grid-column: 2;

        & h1,
        & p,
        & button {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    & .totals {
      max-width: 100%;
    }
  }
`;

const Cart = ({ data }) => {
  const { checkout, loading } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const height = use100vh();

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageColor: `#DDE1D4`,
      pageType: `cart`,
    });
  }, []);

  return (
    <>
      <PageSeo seoTitle={`Bag | SHANNON BOND`} seoText={null} seoImage={null} />

      <Page height={height}>
        <div className="page-container">
          {emptyCart ? (
            <div className="empty-cart-container">
              <p className="message cardo">Your shopping bag is empty</p>
              <p className="">
                <Link to="/" className="uppercase">
                  View Latest Collection
                </Link>
              </p>
            </div>
          ) : (
            <>
              <Table>
                <div className="outer-column left">
                  {checkout.lineItems.map((item) => (
                    <LineItem item={item} key={item.id} />
                  ))}
                </div>

                <div className="outer-column right">
                  <div className="row totals">
                    <div className="title">
                      <h1 className="uppercase">Total</h1>
                    </div>

                    <div className="container">
                      <div className="inner-grid-container line">
                        <div className="column left">
                          <h3 className="uppercase">Subtotal</h3>
                        </div>
                        <div className="column right">
                          <p className="">
                            <ProductPrice
                              price={checkout.subtotalPriceV2.amount}
                              salePrice={null}
                            />
                          </p>
                        </div>
                      </div>

                      <div className="inner-grid-container line">
                        <div className="column left">
                          <h3 className="uppercase">Delivery</h3>
                        </div>
                        <div className="column right">
                          <p className="">calculated at checkout</p>
                        </div>
                      </div>

                      {data.prismicCart.data.enable_us_promo_banner && (
                        <div className="promo-banner line">
                          <PrismicRichText
                            field={
                              data.prismicCart.data.promo_banner_us.richText
                            }
                          />
                        </div>
                      )}

                      <div className="buttons-container">
                        <a href={checkout.webUrl} className="uppercase">
                          Checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Table>
            </>
          )}
        </div>
      </Page>
    </>
  );
};

export const query = graphql`
  query {
    prismicCart {
      data {
        enable_promo_banner
        promo_banner {
          richText
        }
      }
    }
  }
`;

export default Cart;
